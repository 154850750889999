<div class="row align-items-end justify-content-between mb-2">
  <div class="col-auto me-auto">
    <mydendra-search-input
      [control]="searchControl"
      #searchInput
      placeholder="All"
      label="Search"
    ></mydendra-search-input>
  </div>
  <div class="col-auto ms-auto">
    <mydendra-button
      [routerLink]="['create']"
      icon="plus"
      text="User role"
    ></mydendra-button>
  </div>
</div>

@if (pagination$ | async; as pagination) {
  <div>
    <mydendra-container>
      <table mydendra-table>
        <thead>
          <tr>
            <th class="text-start">User Role</th>
            <th>Insights</th>
            <th>GIS Portal & Maps</th>
            <th>Weed manager</th>
            <th>Settings</th>
            <th>All locations</th>
            <th># Users</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody class="position-relative">
          @if (paginatorRef.isLoading$ | async) {
            <mydendra-list-loading></mydendra-list-loading>
          }
          @for (role of pagination.data; track role) {
            <tr>
              <td>
                <a [routerLink]="[role.id]">{{ role.name }}</a>
              </td>
              <td>
                <mydendra-role-permission-indicator
                  [hasPermission]="role.flags.dashboard"
                  permissionName="Insights"
                ></mydendra-role-permission-indicator>
              </td>
              <td>
                <mydendra-role-permission-indicator
                  [hasPermission]="role.flags.gis_portal"
                  permissionName="GIS Portal & Maps"
                ></mydendra-role-permission-indicator>
              </td>
              <td>
                <mydendra-role-permission-indicator
                  [hasPermission]="role.flags.weed_management"
                  permissionName="Weed Manager"
                ></mydendra-role-permission-indicator>
              </td>
              <td>
                <mydendra-role-permission-indicator
                  [hasPermission]="role.flags.settings"
                  permissionName="Settings"
                ></mydendra-role-permission-indicator>
              </td>
              <td>
                <mydendra-role-permission-indicator
                  [hasPermission]="role.flags.all_locations"
                  permissionName="All Location Access"
                ></mydendra-role-permission-indicator>
              </td>
              <td class="text-center">{{ role.memberships.length }}</td>
              <td class="text-center">
                <mydendra-role-status
                  [role]="role"
                  theme="light"
                ></mydendra-role-status>
              </td>
            </tr>
          }
        </tbody>
        <tfoot>
          <tr>
            <td colspan="8">
              <mydendra-pagination
                [pagination]="pagination"
                [paginatorRef]="paginatorRef"
              ></mydendra-pagination>
            </td>
          </tr>
        </tfoot>
      </table>
    </mydendra-container>
  </div>
}
