import { inject, Injectable, InjectionToken } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  PaginatorPlugin,
  QueryEntity,
  StoreConfig,
} from '@datorama/akita';
import {
  HttpMethod,
  NgEntityService,
  NgEntityServiceConfig,
} from '@datorama/akita-ng-entity-service';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { toAkitaPaginatedResponse, DRFPaginatedResponse } from '@dendra/utils';
import { OrganisationMembership } from './organisation-membership';
import { AnalyticsEntity } from '@dendra/entity-analytics';

export type RolePermissionFlags = {
  dashboard?: boolean;
  gis_portal?: boolean;
  weed_management?: boolean;
  settings?: boolean;
  all_locations?: boolean;
};

type RoleOrganisationMembership = {
  id: number;
  user: OrganisationMembership['user'];
  organisation: number;
  active: boolean;
  status: 'ACT' | 'INV' | 'INA';
};

export type Role = {
  id: number;
  name: string;
  organisation: number;
  active: boolean;
  flags: RolePermissionFlags;
  memberships: RoleOrganisationMembership[];
  locations: number[];
};

export interface RoleState extends EntityState<Role, number>, ActiveState {}

@StoreConfig({ name: 'role' })
@Injectable({ providedIn: 'root' })
export class RoleStore extends EntityStore<RoleState> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class RoleQuery extends QueryEntity<RoleState> {
  protected store: RoleStore;

  active$ = this.selectAll({ filterBy: ({ active }) => !!active });

  constructor() {
    const store = inject(RoleStore);

    super(store);
    this.store = store;
  }
}

@NgEntityServiceConfig({ resourceName: 'organisation-role' })
@Injectable({ providedIn: 'root' })
export class RoleService extends NgEntityService<RoleState> {
  protected store: RoleStore;
  query = inject(RoleQuery);

  private analyticsService = inject(AnalyticsEntity);

  constructor() {
    const store = inject(RoleStore);

    super(store);
    this.store = store;
  }

  setActive(id: number) {
    this.store.setActive(id);
  }

  clearActive() {
    this.store.setActive(null);
  }

  getAll(): Observable<Role[]> {
    return this.getHttp()
      .get<DRFPaginatedResponse<Role>>('/api/organisation-role/?page_size=100')
      .pipe(
        map(resp => resp.results),
        tap(roles => this.store.set(roles)),
        catchError(
          error =>
            this.handleError(
              HttpMethod.GET,
              error,
              'Could not get roles',
            ) as Observable<never>,
        ),
      );
  }

  getPage(page = 1, pageSize = 10, search = '') {
    const params = { page: page, page_size: pageSize, search };
    const mapToAkitaResponse = toAkitaPaginatedResponse(
      (x: Role) => x,
      pageSize,
    );
    return this.getHttp()
      .get<DRFPaginatedResponse<Role>>('/api/organisation-role/', { params })
      .pipe(mapToAkitaResponse(page));
  }

  roleAnalyticsEvent = (eventName: string, role: Role) => {
    this.analyticsService.track(eventName, {
      role_id: role.id,
      role_name: role.name,
    });
  };
}

export const ROLE_PAGINATOR = new InjectionToken('ROLE_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    const query = inject(RoleQuery);
    return new PaginatorPlugin(query, { range: true });
  },
});
