import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlingService } from '@services/error-handling.service';
import { Role, RoleService } from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';
import { map } from 'rxjs/operators';
import { RoleFormComponent } from '../role-form/role-form.component';
import { RoleStatusComponent } from '../role-status/role-status.component';

@Component({
  selector: 'mydendra-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss'],
  imports: [
    CommonModule,
    SharedComponentsModule,
    RoleStatusComponent,
    RoleFormComponent,
  ],
})
export class RoleEditComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private title = inject(Title);
  private roleService = inject(RoleService);
  private errorHandling = inject(ErrorHandlingService);

  role$ = this.roleService.query.selectActive();

  model$ = this.roleService.query.selectActive().pipe(
    map((role: Role) => {
      // Deep clone the role object so that it is editable with formly
      return JSON.parse(JSON.stringify(role));
    }),
  );

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle(`Edit | Role | Dendra`);

    this.route.params.subscribe(params => this.loadRole(+params.role_id));
  }

  loadRole(id) {
    this.roleService.get<Role>(id).subscribe({
      next: role => {
        if (!role) {
          this.roleService.setActive(null);
          this.router.navigate(['/not-exists']);
        }
        this.roleService.roleAnalyticsEvent('Edit role', role);
        this.roleService.setActive(role.id);
      },
      error: this.errorHandling.reportErrorAsToast,
    });
  }

  onBack(event) {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  onEdited(event) {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
