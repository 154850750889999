@for (
  locationCollection of locationCollections$ | async;
  track locationCollection
) {
  <div class="accordian-container">
    <div class="dropdown-container">
      <div class="collection">
        <input
          [id]="'collection-checkbox-' + locationCollection.id"
          class="checkbox"
          type="checkbox"
          (click)="
            handleLocationCollectionSelection($event, locationCollection)
          "
          [checked]="collectionChecked[locationCollection.id]"
          [indeterminate]="
            collectionIndeterminate[locationCollection.id] &&
            !collectionChecked[locationCollection.id]
          "
        />
        <label
          [for]="'collection-checkbox-' + locationCollection.id"
          class="collection__label"
          >{{ locationCollection.name }}</label
        >
      </div>
      @if (locationCollection.locations.length > 0) {
        <mydendra-button
          type="action"
          [icon]="
            collectionExpanded[locationCollection.id]
              ? 'chevron-up'
              : 'chevron-down'
          "
          (click)="toggleExpand(locationCollection.id)"
        ></mydendra-button>
      }
    </div>
    <ul
      class="collection__children"
      [collapse]="!collectionExpanded[locationCollection.id]"
      [isAnimated]="true"
    >
      @for (location of locationCollection.locations; track location) {
        <mydendra-location-access-checkbox-row
          [role]="role"
          [location]="location"
          [collectionName]="locationCollection.name"
          [checked]="locationPermissions.includes(location.id)"
          (checkLocation)="handleLocationSelection($event, location.id)"
          [isEditing]="isEditing"
        ></mydendra-location-access-checkbox-row>
      }
    </ul>
  </div>
}
