import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleChoices } from '@dendra/entity-auth';
import { rolePermissionGuard } from '@shared/guards';
import { MembershipCreateComponent } from './membership-create/membership-create.component';
import { MembershipDetailComponent } from './membership-detail/membership-detail.component';
import { MembershipEditComponent } from './membership-edit/membership-edit.component';
import { MembershipListComponent } from './membership-list/membership-list.component';
import { RoleCreateComponent } from './role-create/role-create.component';
import { RoleDetailComponent } from './role-detail/role-detail.component';
import { RoleEditComponent } from './role-edit/role-edit.component';
import { RoleListComponent } from './role-list/role-list.component';
import { SettingsComponent } from './settings/settings.component';

const routes: Routes = [
  { path: '', redirectTo: 'user', pathMatch: 'full' },
  {
    path: '',
    component: SettingsComponent,
    data: { has_access: RoleChoices.settings },
    canActivate: [rolePermissionGuard],
    children: [
      {
        path: 'user',
        children: [
          { path: 'create', component: MembershipCreateComponent },
          {
            path: ':membership_id',
            children: [
              { path: 'edit', component: MembershipEditComponent },
              { path: '', component: MembershipDetailComponent },
            ],
          },
          { path: '', component: MembershipListComponent },
        ],
      },
      {
        path: 'role',
        children: [
          { path: 'create', component: RoleCreateComponent },
          {
            path: ':role_id',
            children: [
              { path: 'edit', component: RoleEditComponent },
              { path: '', component: RoleDetailComponent },
            ],
          },
          { path: '', component: RoleListComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
