import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { LocationCollectionService } from '@services/states/location-collection';
import { Role } from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';
import { SharedDirectivesModule } from '@shared/directives';
import { CollapseDirective } from 'ngx-bootstrap/collapse';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mydendra-role-locations-list',
  templateUrl: './role-locations-list.component.html',
  styleUrls: ['./role-locations-list.component.scss'],
  imports: [
    CommonModule,
    CollapseDirective,
    SharedComponentsModule,
    SharedDirectivesModule,
  ],
})
export class RoleLocationsListComponent implements OnInit {
  private locationCollectionService = inject(LocationCollectionService);

  @Input() role: Role;

  collectionExpanded = {};

  collections$ = this.locationCollectionService.query.selectAll().pipe(
    map(collections =>
      collections.map(c => {
        const locations = c.locations.map(loc => ({
          ...loc,
          checkStatus: this.role?.locations?.includes(loc.id)
            ? 'checked'
            : 'unchecked',
        }));

        let checkStatus = 'unchecked';
        if (c.locations.length > 0) {
          if (
            c.locations.every(loc => this.role?.locations?.includes(loc.id))
          ) {
            checkStatus = 'checked';
          } else if (
            c.locations.some(loc => this.role?.locations?.includes(loc.id))
          ) {
            checkStatus = 'half-checked';
          }
        }
        return {
          ...c,
          locations,
          checkStatus,
        };
      }),
    ),
  );

  ngOnInit() {
    this.locationCollectionService.getLocationCollections().subscribe();
  }

  toggleExpand(collectionId) {
    this.collectionExpanded[collectionId] =
      !this.collectionExpanded[collectionId];
  }
}
