import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import { ComponentsModule } from '@mydendra/components/components.module';
import { OrganisationMembership } from '@services/states/organisation-membership';
import { SharedComponentsModule } from '@shared/components';
import { ToastrService } from 'ngx-toastr';
import { MembershipFormComponent } from '../membership-form/membership-form.component';

@Component({
  selector: 'mydendra-membership-create',
  templateUrl: './membership-create.component.html',
  styleUrls: ['./membership-create.component.scss'],
  imports: [SharedComponentsModule, ComponentsModule, MembershipFormComponent],
})
export class MembershipCreateComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private title = inject(Title);
  private toastr = inject(ToastrService);

  private analyticsService = inject(AnalyticsEntity);

  model: OrganisationMembership;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle('Create | Users | Dendra');
    this.analyticsService.track('Open membership create form');
  }

  onCreated(membership) {
    this.toastr.success(`Invited ${membership.user.email}`);
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  onBack(event) {
    this.analyticsService.track('Cancelled creating membership');
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
