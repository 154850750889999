import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { SharedComponentsModule } from '@shared/components';
import { SharedDirectivesModule } from '@shared/directives';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ComponentsModule } from '../components/components.module';
import {
  AllLocationAccessMessageFieldComponent,
  MyDendraLocationSelectionArrayFieldComponent,
  RoleLocationsListFieldComponent,
  RoleMembershipListFieldComponent,
  RolePermissionIndicatorFieldComponent,
} from './formly';
import { LocationAccessCheckboxRowComponent } from './location-access-checkbox-row/location-access-checkbox-row.component';
import { LocationAccessFormlyFieldComponent } from './location-access-formly-field/location-access-formly-field.component';
import { MembershipCreateComponent } from './membership-create/membership-create.component';
import {
  MembershipDetailComponent,
  RoleReassignmentModalComponent,
} from './membership-detail/membership-detail.component';
import { MembershipEditComponent } from './membership-edit/membership-edit.component';
import {
  MembershipDeactivateComponent,
  MembershipFormComponent,
} from './membership-form/membership-form.component';
import { MembershipListComponent } from './membership-list/membership-list.component';
import { MembershipStatusComponent } from './membership-status/membership-status.component';
import { RoleCreateComponent } from './role-create/role-create.component';
import { RoleDetailComponent } from './role-detail/role-detail.component';
import { RoleEditComponent } from './role-edit/role-edit.component';
import {
  RoleDeactivateComponent,
  RoleFormComponent,
} from './role-form/role-form.component';
import { RoleListComponent } from './role-list/role-list.component';
import { RoleLocationsListComponent } from './role-locations-list/role-locations-list.component';
import { RoleMembershipListComponent } from './role-membership-list/role-membership-list.component';
import { RolePermissionIndicatorComponent } from './role-permission-indicator/role-permission-indicator.component';
import { RoleStatusComponent } from './role-status/role-status.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingsRoutingModule } from './settings-routing.module';

@NgModule({
  exports: [
    RoleMembershipListComponent,
    RolePermissionIndicatorComponent,
    LocationAccessFormlyFieldComponent,
    RoleLocationsListComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    CollapseModule,
    ReactiveFormsModule,
    SettingsRoutingModule,
    TabsModule,
    TooltipModule.forRoot(),
    FormlyModule.forChild({
      types: [
        {
          name: 'role-membership-list',
          component: RoleMembershipListFieldComponent,
        },
        {
          name: 'role-permission-indicator',
          component: RolePermissionIndicatorFieldComponent,
        },
        {
          name: 'role-permission-all-location-access-message',
          component: AllLocationAccessMessageFieldComponent,
        },
        {
          name: 'location-access-selection-array',
          component: MyDendraLocationSelectionArrayFieldComponent,
        },
        {
          name: 'role-locations-list',
          component: RoleLocationsListFieldComponent,
        },
      ],
    }),
    SharedComponentsModule,
    SharedDirectivesModule,
    MembershipCreateComponent,
    MembershipDetailComponent,
    MembershipEditComponent,
    MembershipFormComponent,
    MembershipListComponent,
    MembershipStatusComponent,
    MembershipDeactivateComponent,
    SettingsComponent,
    RoleLocationsListComponent,
    RoleListComponent,
    RoleStatusComponent,
    RoleDetailComponent,
    RoleFormComponent,
    RoleDeactivateComponent,
    RoleMembershipListComponent,
    RoleCreateComponent,
    RoleEditComponent,
    RolePermissionIndicatorComponent,
    RoleReassignmentModalComponent,
    LocationAccessFormlyFieldComponent,
    LocationAccessCheckboxRowComponent,
    RoleMembershipListFieldComponent,
    RoleLocationsListFieldComponent,
    RolePermissionIndicatorFieldComponent,
    AllLocationAccessMessageFieldComponent,
    MyDendraLocationSelectionArrayFieldComponent,
  ],
})
export class SettingsModule {}
