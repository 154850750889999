@for (collection of collections$ | async; track collection) {
  <ul>
    <div class="location-access__collection">
      <div class="location-access__menu-title">
        @switch (collection.checkStatus) {
          @case ('checked') {
            <mydendra-status icon="check" type="success"></mydendra-status>
          }
          @case ('half-checked') {
            <mydendra-status icon="minus" type="info"></mydendra-status>
          }
          @case ('unchecked') {
            <mydendra-status icon="cross" type="danger"></mydendra-status>
          }
        }
        <span (click)="toggleExpand(collection.id)" role="button">{{
          collection.name
        }}</span>
      </div>
      <mydendra-button
        type="action"
        [icon]="
          collectionExpanded[collection.id] ? 'chevron-up' : 'chevron-down'
        "
        (click)="toggleExpand(collection.id)"
      ></mydendra-button>
    </div>
    <ul
      class="location-access__location-list"
      [collapse]="!collectionExpanded[collection.id]"
      [isAnimated]="true"
    >
      @for (location of collection.locations; track location) {
        <li class="location-access__location location-access__menu-title">
          @switch (location.checkStatus) {
            @case ('checked') {
              <mydendra-status icon="check" type="success"></mydendra-status>
            }
            @case ('half-checked') {
              <mydendra-status icon="minus" type="info"></mydendra-status>
            }
            @case ('unchecked') {
              <mydendra-status icon="cross" type="danger"></mydendra-status>
            }
          }
          <p class="location__label">
            {{ location.name }}
          </p>
        </li>
      }
    </ul>
  </ul>
}
