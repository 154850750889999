import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import { Location } from '@mydendra/states/location';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { FlagService } from '@services/states/flags';
import { Role, RoleService } from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';

export interface LocationInterface extends Partial<Location> {
  checked?: boolean;
  parent?: number;
}

@Component({
  selector: 'mydendra-role-deactivation-modal',
  templateUrl: './role-deactivate.component.html',
  imports: [SharedComponentsModule],
})
export class RoleDeactivateComponent {
  private bsModalRef = inject(BsModalRef);

  @Input() role: Role;
  private confirmed = new Subject<boolean>();

  close() {
    this.bsModalRef.hide();
  }

  confirm() {
    this.confirmed.next(true);
    this.close();
  }

  cancel() {
    this.confirmed.next(false);
    this.close();
  }
}

@Component({
  selector: 'mydendra-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
  imports: [CommonModule, FormlyModule, ReactiveFormsModule],
})
export class RoleFormComponent implements OnInit, OnDestroy {
  private roleService = inject(RoleService);
  private modalService = inject(BsModalService);
  private toastr = inject(ToastrService);
  flagService = inject(FlagService);

  @Input() model: Partial<Role>;
  @Input() role$: Observable<Role>;
  @Input() disabled = false;
  @Input() read_only = false;
  @Input() editing = false;
  @Output() created = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() edited = new EventEmitter();

  private analyticsService = inject(AnalyticsEntity);

  form = new FormGroup<{
    flags?: FormControl<Record<string, boolean>>;
    locations?: FormControl<number[]>;
  }>({});
  modalRef: BsModalRef;

  options: FormlyFormOptions = {
    formState: {
      read_only: false,
      disabled: false,
      editing: false,
      all_locations_selected: false, // To toggle visible of the location selector,
    },
  };

  fields: FormlyFieldConfig[];

  ngOnInit(): void {
    this.options.formState.read_only = this.read_only;
    this.options.formState.disabled = this.disabled || this.read_only;
    this.options.formState.editing = this.editing;
    this.options.formState.all_locations_selected =
      this.model?.flags.all_locations;

    this.fields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            fieldGroup: [
              {
                wrappers: ['mydendra-container'],
                hideExpression: 'formState.disabled ',
                fieldGroupClassName: 'd-flex align-items-center',
                templateOptions: {
                  additionalClasses: 'my-l',
                },
                fieldGroup: [
                  {
                    className: 'w-100',
                    type: 'input',
                    key: 'name',
                    templateOptions: {
                      label: 'Role',
                      required: true,
                      attributes: {},
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled',
                    },
                    wrappers: ['mydendra-form-field'],
                  },
                ],
              },
              {
                wrappers: ['mydendra-container'],
                templateOptions: {
                  title: 'Permissions',
                  additionalClasses: 'mb-l',
                },
                fieldGroup: [
                  {
                    type: 'role-permission-indicator',
                    hideExpression: '!formState.disabled',
                    templateOptions: {
                      permissionName: 'Insights',
                    },
                    expressionProperties: {
                      'templateOptions.hasPermission':
                        'model?.flags ? model.flags.dashboard : false',
                    },
                  },
                  {
                    hideExpression: 'formState.disabled',
                    className: 'd-block mb-l',
                    type: 'toggle',
                    key: 'flags.dashboard',
                    templateOptions: {
                      label: 'Insights',
                      required: false,
                      handleToggle: $event => {
                        this.form
                          .get('flags')
                          .get('dashboard')
                          .setValue($event.target.checked);
                      },
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled',
                      'templateOptions.defaultValue':
                        'model?.flags ? model.flags.dashboard : false',
                    },
                  },

                  {
                    type: 'role-permission-indicator',
                    hideExpression: '!formState.disabled',
                    templateOptions: {
                      permissionName: 'GIS Portal & Maps',
                    },
                    expressionProperties: {
                      'templateOptions.hasPermission':
                        'model?.flags ? model.flags.gis_portal : false',
                    },
                  },
                  {
                    hideExpression: 'formState.disabled',
                    className: 'd-block mb-l',
                    type: 'toggle',
                    key: 'flags.gis_portal',
                    templateOptions: {
                      label: 'GIS Portal & Maps',
                      required: false,
                      handleToggle: $event => {
                        this.form
                          .get('flags')
                          .get('gis_portal')
                          .setValue($event.target.checked);
                      },
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled',
                      'templateOptions.defaultValue':
                        'model?.flags ? model.flags.gis_portal : false',
                    },
                  },

                  {
                    type: 'role-permission-indicator',
                    hideExpression: '!formState.disabled',
                    templateOptions: {
                      permissionName: 'Weed manager',
                    },
                    expressionProperties: {
                      'templateOptions.hasPermission':
                        'model?.flags ? model.flags.weed_management : false',
                    },
                  },
                  {
                    hideExpression: 'formState.disabled',
                    className: 'd-block mb-l',
                    type: 'toggle',
                    key: 'flags.weed_management',
                    templateOptions: {
                      label: 'Weed manager',
                      required: false,
                      handleToggle: $event => {
                        this.form
                          .get('flags')
                          .get('weed_management')
                          .setValue($event.target.checked);
                      },
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled',
                      'templateOptions.defaultValue':
                        'model?.flags ? model.flags.weed_management : false',
                    },
                  },

                  {
                    type: 'role-permission-indicator',
                    hideExpression: '!formState.disabled',
                    templateOptions: {
                      permissionName: 'Settings',
                    },
                    expressionProperties: {
                      'templateOptions.hasPermission':
                        'model?.flags ? model.flags.settings : false',
                    },
                  },
                  {
                    hideExpression: 'formState.disabled',
                    className: 'd-block mb-l',
                    type: 'toggle',
                    key: 'flags.settings',
                    templateOptions: {
                      label: 'Settings',
                      required: false,
                      handleToggle: $event => {
                        this.form
                          .get('flags')
                          .get('settings')
                          .setValue($event.target.checked);
                      },
                    },
                    expressionProperties: {
                      'templateOptions.disabled': 'formState.disabled',
                      'templateOptions.defaultValue':
                        'model?.flags ? model.flags.settings : false',
                    },
                  },
                  {
                    fieldGroupClassName: 'mb-l',
                    fieldGroup: [
                      {
                        type: 'role-permission-indicator',
                        hideExpression:
                          '!formState.disabled | !model?.flags?.all_locations',
                        templateOptions: {
                          permissionName: 'All location access',
                        },
                        expressionProperties: {
                          'templateOptions.hasPermission':
                            'model?.flags ? model.flags.all_locations : false',
                        },
                      },
                      {
                        hideExpression: 'formState.disabled',
                        type: 'toggle',
                        key: 'flags.all_locations',
                        templateOptions: {
                          label: 'All location access',
                          required: false,
                          handleToggle: $event => {
                            this.form
                              .get('flags')
                              .get('all_locations')
                              .setValue($event.target.checked);
                            this.options.formState.all_locations_selected =
                              $event.target.checked;
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': 'formState.disabled',
                          'templateOptions.defaultValue':
                            'model?.flags ? model.flags.all_locations : false',
                        },
                      },
                      {
                        type: 'role-locations-list',
                        hideExpression:
                          '!formState.disabled | !model?.id | model?.flags?.all_locations',
                        templateOptions: {
                          label: 'Restricted Location Access',
                        },
                        expressionProperties: {
                          'templateOptions.role': 'model',
                        },
                        wrappers: ['mydendra-form-field'],
                      },
                      {
                        key: 'locations',
                        type: 'location-access-selection-array',
                        templateOptions: {
                          setLocationValue: (locId: number) => {
                            if (this.form.value?.locations?.includes(locId)) {
                              this.form.controls.locations.setValue(
                                this.form.value.locations.filter(
                                  l => l != locId,
                                ),
                              );
                            } else {
                              if (this.form.value?.locations) {
                                this.form.controls.locations.setValue([
                                  ...this.form.value.locations,
                                  locId,
                                ]);
                              } else {
                                this.form.controls.locations.setValue([locId]);
                              }
                            }
                          },
                          setCollectionValue: (
                            locIds: number[],
                            isAdding: boolean,
                          ) => {
                            if (isAdding && this.form.value?.locations) {
                              const locIdsToAdd = locIds.filter(
                                id => !this.form.value.locations.includes(id),
                              );
                              this.form.controls.locations.setValue([
                                ...this.form.value.locations,
                                ...locIdsToAdd,
                              ]);
                            } else if (isAdding) {
                              this.form.controls.locations.setValue(locIds);
                            } else {
                              this.form.controls.locations.setValue(
                                this.form.value.locations.filter(
                                  l => !locIds.includes(l),
                                ),
                              );
                            }
                          },
                        },
                        hideExpression:
                          'formState.all_locations_selected || formState.disabled',
                        expressionProperties: {
                          'templateOptions.role': 'model',
                          'templateOptions.locationCollections':
                            'model?.locations ?? []',
                          'templateOptions.isEditing': 'formState.editing',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                wrappers: ['mydendra-container'],
                hideExpression: '!model.active || formState.editing',
                fieldGroupClassName:
                  'd-flex role-form-deactivate-container align-items-center',
                fieldGroup: [
                  {
                    type: 'button',
                    key: 'deactivate',
                    className: 'text-nowrap',
                    templateOptions: {
                      text: 'Deactivate role',
                      type: 'warning',
                      icon: 'window-close',
                      onClick: $event => {
                        $event.preventDefault();
                        this.deactivate(this.model);
                      },
                    },
                  },
                  {
                    template: `
                      <span class="body text-secondary-grey">
                        You will not be able to add more users to this role. </br>
                        All users with this role will lose all access to your organisation's data in myDendra.
                      </span>
                      `,
                    className:
                      'role-form-deactivate-container-text settings-form-deactivate-container-text ',
                  },
                ],
              },
              {
                fieldGroupClassName:
                  'd-flex justify-content-between align-items-center',
                fieldGroup: [
                  {
                    type: 'button',
                    key: 'cancel',
                    hideExpression: 'formState.disabled',
                    templateOptions: {
                      text: 'Cancel',
                      type: 'link',
                      icon: 'cancel',
                      onClick: $event => {
                        $event.preventDefault();
                        if (!this.model?.id) {
                          this.handle_back($event);
                        } else {
                          this.edit_cancel(this.model);
                        }
                      },
                    },
                  },
                  {
                    className: 'ml-l',
                    type: 'button',
                    key: 'submit',
                    hideExpression: 'formState.disabled',
                    expressionProperties: {
                      'templateOptions.text':
                        'model?.id ? "Save" : "Create role"',
                      'templateOptions.disabled': () => {
                        return !this.form.valid;
                      },
                    },
                    templateOptions: {
                      button_type: 'submit',
                      type: 'primary',
                      icon: 'save',
                    },
                  },
                ],
              },
            ],
          },
          {
            wrappers: ['mydendra-container'],
            className: 'col-6',
            hideExpression: '!formState.disabled || formState.editing',
            templateOptions: {
              title: 'Users with this role',
              additionalClasses: 'mb-l',
            },
            fieldGroup: [
              {
                type: 'role-membership-list',
                templateOptions: {
                  role: this.role$,
                },
              },
            ],
          },
        ],
      },
    ];
  }

  ngOnDestroy(): void {
    this.roleService.clearActive();
  }

  handle_back(event) {
    this.back.emit(event);
  }

  save(model) {
    if (!this.form.valid) {
      return false;
    }

    if (this.model?.id) {
      this.edit_save(model);
    } else {
      this.create_save(this.form.value);
    }
  }

  deactivate(model) {
    this.roleService.roleAnalyticsEvent('Deactivated role', model);

    this.modalRef = this.modalService.show(RoleDeactivateComponent, {
      backdrop: 'static',
      class: 'modal modal-dialog-centered',
      initialState: { role: model },
    });

    this.modalRef.content.confirmed.subscribe((is_confirmed: boolean) => {
      if (is_confirmed) {
        this.roleService.roleAnalyticsEvent('Deactivate role confirmed', model);
        this.roleService.update(model.id, { active: false }).subscribe({
          next: role => this.edited.emit(role),
          error: err => this.toastr.error(err.error),
        });
      } else {
        this.roleService.roleAnalyticsEvent('Deactivate role cancelled', model);
      }
    });
  }

  create_save(data) {
    this.roleService.add<Role>(data).subscribe({
      next: role => {
        this.roleService.roleAnalyticsEvent('Created role', role);
        this.created.emit(role);
      },
      error: err => {
        this.options.formState.disabled = false;
        this.onError(err);
      },
    });
  }

  edit_cancel(model) {
    this.options.resetModel();
    this.roleService.roleAnalyticsEvent('Editing role cancelled', model);
    this.back.emit(event);
  }

  edit_save(data) {
    // Rely on form data as the underlying model is mutated by formly
    // to be managed through getters & setters, and cannot be posted directly
    const { flags, locations } = this.form.value;
    const updatedRole = { flags, locations };
    this.options.formState.disabled = true;
    this.roleService.update<Role>(data.id, updatedRole).subscribe({
      next: role => {
        this.roleService.roleAnalyticsEvent('Editing role saved', role);
        this.edited.emit(role);
        this.options.formState.disabled = true;
      },
      error: err => {
        this.options.formState.disabled = false;
        this.onError(err);
      },
    });
  }

  onError(err) {
    this.analyticsService.track('Role form action failed', {
      error: err.error[0],
    });
    const errorArray = Object.entries(err.error);
    errorArray.forEach(entry => {
      const [field, error] = entry;
      if (field === 'flags') {
        this.toastr.error(
          'You must select at least one permission for this role',
        );
      } else {
        this.toastr.error(error as string);
      }
    });
  }
}
