import { Component } from '@angular/core';
import { FieldType, FieldWrapper } from '@ngx-formly/core';
import { SharedComponentsModule } from '@shared/components';
import { LocationAccessFormlyFieldComponent } from './location-access-formly-field/location-access-formly-field.component';
import { RoleLocationsListComponent } from './role-locations-list/role-locations-list.component';
import { RoleMembershipListComponent } from './role-membership-list/role-membership-list.component';
import { RolePermissionIndicatorComponent } from './role-permission-indicator/role-permission-indicator.component';

@Component({
  selector: 'mydendra-role-membership-list-templates',
  template: `
    <div>
      <mydendra-role-membership-list
        [role]="to.role"
      ></mydendra-role-membership-list>
    </div>
  `,
  imports: [RoleMembershipListComponent],
})
export class RoleMembershipListFieldComponent extends FieldWrapper {}

@Component({
  selector: 'mydendra-role-locations-list-templates',
  template: `
    <div>
      <mydendra-role-locations-list
        [role]="to.role"
      ></mydendra-role-locations-list>
    </div>
  `,
  imports: [RoleLocationsListComponent],
})
export class RoleLocationsListFieldComponent extends FieldWrapper {}

@Component({
  selector: 'mydendra-role-permission-indicator-template',
  template: `
    <div class="mb-m">
      <mydendra-role-permission-indicator
        [isCollapsed]="false"
        [hasPermission]="to.hasPermission"
        [permissionName]="to.permissionName"
      >
      </mydendra-role-permission-indicator>
      <ng-template #fieldComponent></ng-template>
    </div>
  `,
  imports: [RolePermissionIndicatorComponent],
})
export class RolePermissionIndicatorFieldComponent extends FieldWrapper {}

@Component({
  selector: 'mydendra-formly-location-access-message',
  template: `
    <div>
      <mydendra-icon name="info-context"></mydendra-icon>
      <span class="ml-xxs"
        >This role has access to all locations (existing and future)</span
      >
      <ng-template #fieldComponent></ng-template>
    </div>
  `,
  styles: [
    `
      div {
        display: flex;
        flex-direction: row;
        align-items: start;
        line-height: 110%;
        font-size: var(--small-font-size);
        color: var(--color-secondary-grey);
      }
    `,
  ],
  imports: [SharedComponentsModule],
})
export class AllLocationAccessMessageFieldComponent extends FieldType {}

@Component({
  selector: 'mydendra-formly-location-selection-array',
  template: `
    <mydendra-location-access-formly-field
      [locationPermissions]="to.locationCollections"
      [role]="to.role"
      [isEditing]="to.isEditing"
      [setLocationValue]="to.setLocationValue"
      [setCollectionValue]="to.setCollectionValue"
    >
    </mydendra-location-access-formly-field>
  `,
  imports: [LocationAccessFormlyFieldComponent],
})
export class MyDendraLocationSelectionArrayFieldComponent extends FieldType {}
