import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '@shared/components';
import { SharedPipesModule } from '@shared/pipes';

@Component({
  selector: 'mydendra-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    SharedPipesModule,
  ],
})
export class SettingsComponent implements OnInit {
  private title = inject(Title);

  ngOnInit(): void {
    this.title.setTitle('Settings | Dendra');
  }
}
