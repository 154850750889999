@if (membership$ | async; as membership) {
  <div>
    <mydendra-container class="mb-l">
      <div
        class="d-flex flex-row justify-content-between align-items-center w-100"
      >
        <div class="membership-edit__header d-flex flex-row">
          <mydendra-membership-status
            [membership]="membership"
            theme="light"
          ></mydendra-membership-status>
          <div class="d-flex flex-column ml-xl">
            <h1 class="subtitle mb-0">{{ membership.user.email }}</h1>
            <h2 class="mb-0">Identity used across the portal</h2>
          </div>
        </div>
      </div>
    </mydendra-container>
    <mydendra-membership-form
      [editing]="true"
      [model]="model$ | async"
      (edited)="onEdited($event)"
      (back)="onBack($event)"
    ></mydendra-membership-form>
  </div>
} @else {
  <mydendra-loading></mydendra-loading>
}
