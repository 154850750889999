@switch (role.active) {
  @case (true) {
    <mydendra-status
      type="success"
      [theme]="theme"
      text="Active"
    ></mydendra-status>
  }
  @case (false) {
    <mydendra-status
      type="warning"
      [theme]="theme"
      text="Inactive"
    ></mydendra-status>
  }
}
