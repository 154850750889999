<div [class.indicator::collapsed]="isCollapsed">
  @switch (isCollapsed) {
    @case (true) {
      @switch (hasPermission) {
        @case (true) {
          <mydendra-icon name="check" class="has-permission"></mydendra-icon>
        }
        @case (false) {
          <mydendra-icon name="cross" class="no-permission"></mydendra-icon>
        }
      }
    }
    @case (false) {
      @switch (hasPermission) {
        @case (true) {
          <mydendra-status
            type="success"
            [theme]="theme"
            [text]="permissionName"
            icon_position="left"
            icon="check"
            [no_line_break]="true"
          ></mydendra-status>
        }
        @case (false) {
          <mydendra-status
            type="danger"
            [theme]="theme"
            [text]="permissionName"
            icon_position="left"
            icon="cross"
            [no_line_break]="true"
          ></mydendra-status>
        }
      }
    }
  }
</div>
