import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, inject } from '@angular/core';
import {
  LocationCollection,
  LocationCollectionService,
} from '@services/states/location-collection';
import { Role } from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';
import { CollapseDirective } from 'ngx-bootstrap/collapse';
import { Observable } from 'rxjs';
import { LocationAccessCheckboxRowComponent } from '../location-access-checkbox-row/location-access-checkbox-row.component';

@Component({
  selector: 'mydendra-location-access-formly-field',
  templateUrl: './location-access-formly-field.component.html',
  styleUrls: ['./location-access-formly-field.component.scss'],
  imports: [
    CommonModule,
    SharedComponentsModule,
    CollapseDirective,
    LocationAccessCheckboxRowComponent,
  ],
})
export class LocationAccessFormlyFieldComponent implements OnInit, OnChanges {
  private locationCollectionService = inject(LocationCollectionService);

  @Input() setLocationValue;
  @Input() setCollectionValue;
  @Input() locationPermissions: number[];
  @Input() isEditing: boolean;
  @Input() role: Role;

  collectionExpanded = {};
  collectionChecked = {};
  collectionIndeterminate = {};
  locationCollections$: Observable<LocationCollection[]> =
    this.locationCollectionService.query.selectAll();

  setCollectionCheckState(locationCollections: LocationCollection[]) {
    const checks = {},
      indeterminates = {}; // temp vars to collect info in loop
    locationCollections.forEach(collection => {
      checks[collection.id] =
        collection.locations.length > 0
          ? collection.locations.every(loc =>
              this.locationPermissions.includes(loc.id),
            )
          : false;
      indeterminates[collection.id] =
        collection.locations.length > 0
          ? collection.locations.some(loc =>
              this.locationPermissions.includes(loc.id),
            )
          : false;
    });
    this.collectionChecked = checks;
    this.collectionIndeterminate = indeterminates;
  }

  ngOnInit(): void {
    this.locationCollectionService.getLocationCollections().subscribe(cols => {
      this.setCollectionCheckState(cols);
    });
  }

  ngOnChanges(): void {
    const locationCollections = this.locationCollectionService.query.getAll();
    this.setCollectionCheckState(locationCollections);
  }

  handleLocationSelection($event, locationId: number) {
    this.setLocationValue(locationId, $event.target.checked);
  }

  handleLocationCollectionSelection(
    $event,
    locationCollection: LocationCollection,
  ) {
    const locationIds = locationCollection.locations.map(l => l.id);
    this.setCollectionValue(locationIds, $event.target.checked);
  }

  toggleExpand(collectionId) {
    this.collectionExpanded[collectionId] =
      !this.collectionExpanded[collectionId];
  }
}
