import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import {
  Role,
  RoleService,
  ROLE_PAGINATOR,
  RoleState,
} from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, startWith, switchMap, tap } from 'rxjs/operators';
import { RolePermissionIndicatorComponent } from '../role-permission-indicator/role-permission-indicator.component';
import { RoleStatusComponent } from '../role-status/role-status.component';

@Component({
  selector: 'mydendra-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    RolePermissionIndicatorComponent,
    RoleStatusComponent,
  ],
})
export class RoleListComponent implements OnDestroy, OnInit {
  paginatorRef = inject<PaginatorPlugin<RoleState>>(ROLE_PAGINATOR);
  private roleService = inject(RoleService);
  private title = inject(Title);

  pagination$: Observable<PaginationResponse<Role>>;
  searchControl = new FormControl();

  private analyticsService = inject(AnalyticsEntity);

  @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle(`Roles | Dendra`);

    this.analyticsService.track('Role list - view');

    this.paginatorRef.clearCache();
    this.paginatorRef.setFirstPage();

    const searchValue = this.searchControl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
    );
    this.pagination$ = combineLatest([
      this.paginatorRef.pageChanges,
      searchValue.pipe(
        tap(search => {
          if (search) {
            this.analyticsService.track('Role list - search', { search });
          }
          this.paginatorRef.clearCache();
          this.paginatorRef.setFirstPage();
        }),
      ),
    ]).pipe(
      switchMap(([page, search]) => {
        const request = () => this.roleService.getPage(page, 10, search);
        return this.paginatorRef.getPage(request);
      }),
    );
  }

  setFocus() {
    this.searchInput.nativeElement.focus();
  }

  ngOnDestroy() {
    this.paginatorRef.clearCache();
    this.paginatorRef.destroy();
  }
}
