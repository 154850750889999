<div class="modal-header d-flex justify-content-between align-items-center">
  <h1 class="heading-4 mb-0">Reactivate user</h1>
  <mydendra-icon
    name="cross"
    class="btn-close"
    (click)="cancel()"
    role="button"
  ></mydendra-icon>
</div>
<div class="modal-body d-flex flex-column align-items-center">
  <p class="mb-xs">The user’s current role is inactive.</p>
  <p class="mb-xs text-center">
    To reactivate this user, you must assign them an active role.
  </p>
  <div class="w-75">
    <mydendra-dropdown
      [options]="roles"
      [readOnly]="false"
      [required]="true"
      title="User role"
      (selectValue)="onSelect($event)"
    ></mydendra-dropdown>
  </div>
</div>
<div class="modal-footer d-flex justify-content-between">
  <mydendra-button
    type="link"
    icon="cancel"
    icon_position="left"
    (click)="cancel()"
    text="Cancel"
  ></mydendra-button>
  <mydendra-button
    type="primary"
    icon="check"
    icon_position="right"
    (click)="confirm()"
    text="Reactivate"
    [disabled]="selection === undefined"
  ></mydendra-button>
</div>
