<div class="row align-items-end justify-content-between mb-2">
  <div class="col-auto me-auto">
    <mydendra-search-input
      [control]="searchControl"
      #searchInput
      placeholder="All"
      label="Search"
    ></mydendra-search-input>
  </div>
  <div class="col-auto ms-auto">
    <mydendra-button
      [routerLink]="['create']"
      icon="plus"
      text="User"
    ></mydendra-button>
  </div>
</div>
@if (pagination$ | async; as pagination) {
  <div>
    <mydendra-container>
      <table mydendra-table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Role</th>
            <th class="text-center">Status</th>
          </tr>
        </thead>
        <tbody class="position-relative">
          @if (paginatorRef.isLoading$ | async) {
            <mydendra-list-loading></mydendra-list-loading>
          }
          @for (membership of pagination.data; track membership) {
            <tr>
              @if (membership.user.email) {
                <td>
                  <a [routerLink]="[membership.id]">{{
                    membership.user.email
                  }}</a>
                </td>
              } @else {
                <td>---</td>
              }
              @if (membership) {
                <td>
                  {{ membership.role.name }}
                </td>
              } @else {
                <td>---</td>
              }
              <td class="text-center">
                <mydendra-membership-status
                  [membership]="membership"
                  theme="light"
                ></mydendra-membership-status>
              </td>
            </tr>
          }
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5">
              <mydendra-pagination
                [pagination]="pagination"
                [paginatorRef]="paginatorRef"
              ></mydendra-pagination>
            </td>
          </tr>
        </tfoot>
      </table>
    </mydendra-container>
  </div>
}
