import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Role } from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';
import { Observable } from 'rxjs';
import { MembershipStatusComponent } from '../membership-status/membership-status.component';

@Component({
  selector: 'mydendra-role-membership-list',
  templateUrl: './role-membership-list.component.html',
  styleUrls: ['./role-membership-list.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    MembershipStatusComponent,
  ],
})
export class RoleMembershipListComponent {
  @Input() role: Observable<Role>;
}
