@if (role$ | async; as role) {
  <div>
    <div
      class="d-flex flex-row align-items-center justify-content-between mb-s"
    >
      <a
        [routerLink]="['/settings/role']"
        class="d-flex flex-row align-items-center"
      >
        <mydendra-icon name="chevron-left"></mydendra-icon>Back
      </a>
    </div>
    <mydendra-container class="mb-l">
      <div
        class="d-flex flex-row justify-content-between align-items-center w-100"
      >
        <div class="role-detail__header d-flex flex-row">
          <mydendra-role-status
            [role]="role"
            theme="light"
          ></mydendra-role-status>
          <div class="d-flex flex-column ml-xl">
            <h1 class="subtitle mb-0">{{ role.name }}</h1>
            <h2 class="mb-0">Role</h2>
          </div>
        </div>
        <div class="role-detail__actions d-flex flex-row">
          @if (role.active) {
            <mydendra-button
              icon="edit"
              [routerLink]="['edit']"
              text="Edit role"
            ></mydendra-button>
          }
          @if (!role.active) {
            <mydendra-button
              icon="check"
              (click)="reactivate(role)"
              text="Reactivate"
            ></mydendra-button>
          }
        </div>
      </div>
    </mydendra-container>
    <mydendra-role-form
      [role$]="role$"
      [model]="role"
      [read_only]="true"
    ></mydendra-role-form>
  </div>
} @else {
  <mydendra-loading></mydendra-loading>
}
