@if (role | async; as r) {
  <table class="w-100">
    <tbody class="position-relative">
      @for (membership of r.memberships; track membership) {
        <tr>
          <td>
            <a [routerLink]="['../../user/', membership.id]">{{
              membership.user.email
            }}</a>
          </td>
          <td class="text-end pr">
            <mydendra-membership-status
              [membership]="membership"
              theme="light"
            ></mydendra-membership-status>
          </td>
        </tr>
      }
    </tbody>
  </table>
}
