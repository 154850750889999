@switch (membership.status) {
  @case ('ACT') {
    <mydendra-status
      type="success"
      [theme]="theme"
      text="Active"
    ></mydendra-status>
  }
  @case ('INA') {
    <mydendra-status
      type="warning"
      [theme]="theme"
      text="Inactive"
    ></mydendra-status>
  }
  @case ('INV') {
    <mydendra-status
      type="info"
      [theme]="theme"
      text="Invited"
    ></mydendra-status>
  }
  @case ('EXP') {
    <mydendra-status
      type="danger"
      [theme]="theme"
      text="Invite expired"
    ></mydendra-status>
  }
  @case ('FAI') {
    <mydendra-status
      type="danger"
      [theme]="theme"
      text="Invite failed"
    ></mydendra-status>
  }
}
