<div class="location">
  <input
    [id]="'location__checkbox--' + location.id"
    class="checkbox"
    type="checkbox"
    (click)="checkLocation.emit($event)"
    [checked]="checked"
  />
  <label
    [for]="'location__checkbox--' + location.id"
    class="location__label body"
    >{{ location.name }}</label
  >
</div>
