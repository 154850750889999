import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OrganisationMembership,
  OrganisationMembershipService,
} from '@services/states/organisation-membership';
import { SharedComponentsModule } from '@shared/components';
import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'rxjs/operators';
import { MembershipFormComponent } from '../membership-form/membership-form.component';
import { MembershipStatusComponent } from '../membership-status/membership-status.component';

@Component({
  selector: 'mydendra-membership-edit',
  templateUrl: './membership-edit.component.html',
  imports: [
    CommonModule,
    SharedComponentsModule,
    MembershipStatusComponent,
    MembershipFormComponent,
  ],
})
export class MembershipEditComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private title = inject(Title);
  private toastr = inject(ToastrService);
  private membershipService = inject(OrganisationMembershipService);

  membership$ = this.membershipService.query.selectActive();

  model$ = this.membership$.pipe(
    map((membership: OrganisationMembership) => {
      // Deep clone the membership object so that it is editable with formly
      return JSON.parse(JSON.stringify(membership));
    }),
  );

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle(`Edit | Users | Dendra`);
    this.route.params.subscribe(params =>
      this.loadMembership(+params.membership_id),
    );
  }

  loadMembership(id) {
    this.membershipService
      .get<OrganisationMembership>(id)
      .pipe(
        tap(membership => {
          if (!membership) {
            this.membershipService.setActive(null);
            this.router.navigate(['/not-exists']);
          }
          this.membershipService.organisationMembershipAnalyticsEvent(
            'Edit membership',
            membership,
          );
          this.membershipService.setActive(membership.id);
        }),
      )
      .subscribe();
  }

  onBack(event) {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  onEdited(membership) {
    this.toastr.success(`Edited ${membership.user.email}`);
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
