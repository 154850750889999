import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ErrorHandlingService } from '@services/error-handling.service';
import {
  OrganisationMembership,
  OrganisationMembershipService,
} from '@services/states/organisation-membership';
import { Role, RoleService } from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { MembershipFormComponent } from '../membership-form/membership-form.component';
import { MembershipStatusComponent } from '../membership-status/membership-status.component';

@Component({
  selector: 'mydendra-role-reassignment-modal',
  templateUrl: './role-reassignment-modal.component.html',
  imports: [SharedComponentsModule],
})
export class RoleReassignmentModalComponent implements OnInit {
  private bsModalRef = inject(BsModalRef);
  private roleService = inject(RoleService);

  @Input() membership: OrganisationMembership;
  @Output() roleSelection = new EventEmitter();

  public selection: Role;
  public confirmed: Subject<boolean>;
  public roles: Observable<Role[]> = this.roleService.query.active$;

  ngOnInit() {
    this.confirmed = new Subject<boolean>();
  }

  onSelect($event) {
    this.selection = $event;
  }

  close() {
    this.bsModalRef.hide();
  }

  confirm() {
    this.confirmed.next(true);
    this.roleSelection.emit(this.selection);
    this.close();
  }

  cancel() {
    this.confirmed.next(false);
    this.close();
  }
}

@Component({
  selector: 'mydendra-membership-detail',
  templateUrl: './membership-detail.component.html',
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    MembershipStatusComponent,
    MembershipFormComponent,
  ],
})
export class MembershipDetailComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private title = inject(Title);
  private toastr = inject(ToastrService);
  private organisationMembershipService = inject(OrganisationMembershipService);
  private modalService = inject(BsModalService);
  private errorHandling = inject(ErrorHandlingService);

  @Output() edited = new EventEmitter();

  membership$ = this.organisationMembershipService.query
    .selectActive()
    .pipe(map(obj => structuredClone(obj)));
  modalRef: BsModalRef;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle(`Detail | Users | Dendra`);

    this.route.params.subscribe(params =>
      this.loadMembership(+params.membership_id),
    );
  }

  reactivate(model: OrganisationMembership) {
    this.organisationMembershipService.organisationMembershipAnalyticsEvent(
      'Reactivate membership',
      model,
    );
    if (!model.role.active) {
      // If user does not have an active role, open role reassignment modal
      this.modalRef = this.modalService.show(RoleReassignmentModalComponent, {
        backdrop: 'static',
        class: 'modal modal-dialog-centered',
        initialState: { membership: model },
      });

      // Pass the 'roleSelection' to the orgMembershipService.change method
      this.modalRef.content.roleSelection
        .pipe(
          switchMap((role: Role) => {
            this.organisationMembershipService.organisationMembershipAnalyticsEvent(
              'Reactivate membership confirmed',
              model,
            );
            const updatedModel = { ...model, role, active: true };
            return this.organisationMembershipService.update<OrganisationMembership>(
              model.id,
              this.organisationMembershipService.entityToData(updatedModel),
            );
          }),
        )
        .subscribe({
          next: (membership: OrganisationMembership) => {
            this.toastr.success(`Reactivated ${membership.user.email}`);
            this.edited.emit(membership);
          },
          error: this.errorHandling.reportErrorAsToast,
        });
    } else {
      this.organisationMembershipService.toggleActive(model).subscribe({
        next: membership => {
          this.toastr.success(`Reactivated ${membership.user.email}`);
          this.edited.emit(membership);
        },
      });
    }
  }

  loadMembership(id: number) {
    this.organisationMembershipService
      .get<OrganisationMembership>(id)
      .subscribe({
        next: membership => {
          if (!membership) {
            this.organisationMembershipService.setActive(null);
            this.router.navigate(['/not-exists']);
          }
          this.organisationMembershipService.organisationMembershipAnalyticsEvent(
            'View Membership',
            membership,
          );
          this.organisationMembershipService.setActive(membership.id);
        },
        error: this.errorHandling.reportErrorAsToast,
      });
  }

  resendInvite() {
    this.membership$
      .pipe(
        take(1),
        switchMap(membership => {
          this.organisationMembershipService.organisationMembershipAnalyticsEvent(
            'Resend invite',
            membership,
          );
          return this.organisationMembershipService.resendInvite(membership.id);
        }),
      )
      .subscribe({
        next: membership =>
          this.toastr.success(`Resent invite to ${membership.user.email}`),
        error: this.errorHandling.reportErrorAsToast,
      });
  }

  copyToClipboard(inviteUrl: string) {
    navigator.clipboard.writeText(inviteUrl);
    this.toastr.success('The invitation url has been copied to your clipboard');
  }
}
