@if (membership$ | async; as membership) {
  <div>
    <div
      class="d-flex flex-row align-items-center justify-content-between mb-s"
    >
      <a
        [routerLink]="['/settings/user']"
        class="d-flex flex-row align-items-center"
      >
        <mydendra-icon name="chevron-left"></mydendra-icon>Back
      </a>
    </div>
    <mydendra-container class="mb-l">
      <div
        class="d-flex flex-row justify-content-between align-items-center w-100"
      >
        <div class="membership-detail__header d-flex flex-row">
          <mydendra-membership-status
            [membership]="membership"
            theme="light"
          ></mydendra-membership-status>
          <div class="d-flex flex-column ml-xl">
            <h1 class="subtitle mb-0">{{ membership.user.email }}</h1>
            <h2 class="mb-0">Identity used across the portal</h2>
          </div>
        </div>
        <div class="membership-detail__actions d-flex flex-row">
          @if (
            membership.active &&
            membership.status !== 'ACT' &&
            membership.invite_url
          ) {
            <mydendra-button
              class="mr-xs"
              icon="copy"
              (click)="copyToClipboard(membership.invite_url)"
              type="link"
              text="Copy invitation link"
            ></mydendra-button>
          }
          @if (membership.active && membership.status !== 'ACT') {
            <mydendra-button
              class="mr-xs"
              icon="play-start"
              (click)="resendInvite()"
              type="link"
              text="Resend invitation"
            ></mydendra-button>
          }
          @if (membership.active) {
            <mydendra-button
              icon="edit"
              [routerLink]="['edit']"
              text="Edit user"
            ></mydendra-button>
          }
          @if (!membership.active) {
            <mydendra-button
              icon="check"
              (click)="reactivate(membership)"
              text="Reactivate"
            ></mydendra-button>
          }
        </div>
      </div>
    </mydendra-container>
    <mydendra-membership-form
      [model]="membership"
      [read_only]="true"
    ></mydendra-membership-form>
  </div>
} @else {
  <mydendra-loading></mydendra-loading>
}
