import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@mydendra/states/location';
import { Role } from '@services/states/role';

@Component({
  selector: 'mydendra-location-access-checkbox-row',
  templateUrl: './location-access-checkbox-row.component.html',
  styleUrls: ['./location-access-checkbox-row.component.scss'],
})
export class LocationAccessCheckboxRowComponent {
  @Input() location: Pick<Location, 'id' | 'name' | 'shortname'>;
  @Input() collectionName: string;
  @Input() checked: boolean;
  @Input() isEditing = true;
  @Input() role: Role;

  @Output() checkLocation = new EventEmitter();
}
