import { Component, Input } from '@angular/core';
import { SharedComponentsModule } from '@shared/components';

@Component({
  selector: 'mydendra-role-permission-indicator',
  templateUrl: './role-permission-indicator.component.html',
  styleUrls: ['./role-permission-indicator.component.scss'],
  imports: [SharedComponentsModule],
})
export class RolePermissionIndicatorComponent {
  @Input() isCollapsed = true;
  @Input() hasPermission: boolean;
  @Input() permissionName: string;
  @Input() theme: 'solid' | 'light' = 'light';
}
