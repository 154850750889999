import { inject, Injectable, InjectionToken } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  PaginatorPlugin,
  QueryEntity,
  StoreConfig,
} from '@datorama/akita';
import { HttpMethod, NgEntityService } from '@datorama/akita-ng-entity-service';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import { DRFPaginatedResponse, toAkitaPaginatedResponse } from '@dendra/utils';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Role } from './role';

type OrganisationUser = {
  id: number;
  email: string;
  enforce_tfa: boolean;
};

export type OrganisationMembership = {
  id: number;
  role: Role;
  user: OrganisationUser;
  organisation: number;
  active: boolean;
  tfa_required: boolean;
  status: 'ACT' | 'INV' | 'INA' | 'FAI' | 'EXP';
  invite_url: string | null;
};

export interface OrganisationMembershipState
  extends EntityState<OrganisationMembership, number>,
    ActiveState {}

@StoreConfig({ name: 'organisation-membership' })
@Injectable({ providedIn: 'root' })
export class OrganisationMembershipStore extends EntityStore<OrganisationMembershipState> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class OrganisationMembershipQuery extends QueryEntity<OrganisationMembershipState> {
  protected store: OrganisationMembershipStore;

  constructor() {
    const store = inject(OrganisationMembershipStore);

    super(store);
    this.store = store;
  }
}

@Injectable({ providedIn: 'root' })
export class OrganisationMembershipService extends NgEntityService<OrganisationMembershipState> {
  protected store: OrganisationMembershipStore;
  query = inject(OrganisationMembershipQuery);

  private analyticsService = inject(AnalyticsEntity);

  constructor() {
    const store = inject(OrganisationMembershipStore);

    super(store);
    this.store = store;
  }

  setActive(id: number) {
    this.store.setActive(id);
  }

  entityToData(model) {
    return {
      ...model,
      role: model.role?.id,
      user: model.user?.id,
      email: model.user?.email,
      tfa_required: model.tfa_required || false,
    };
  }

  toggleActive(entity: OrganisationMembership) {
    return this.update<OrganisationMembership>(entity.id, {
      active: !entity.active,
    });
  }

  resendInvite(id: number): Observable<OrganisationMembership> {
    const url = `/api/organisation-membership/${id}/resend_invite`;
    return this.getHttp()
      .post<OrganisationMembership>(url, {})
      .pipe(
        tap(membership => this.store.update(id, membership)),
        catchError(
          error =>
            this.handleError(
              HttpMethod.POST,
              error,
              'Could not update membership',
            ) as Observable<never>,
        ),
      );
  }

  getPage(page = 1, pageSize = 10, search = '') {
    const url = '/api/organisation-membership/';
    const params = { page: page, page_size: pageSize, search };
    const mapToAkitaResponse = toAkitaPaginatedResponse<OrganisationMembership>(
      x => x,
      pageSize,
    );
    return this.getHttp()
      .get<DRFPaginatedResponse<OrganisationMembership>>(url, { params })
      .pipe(mapToAkitaResponse(page));
  }

  organisationMembershipAnalyticsEvent = (
    eventName,
    membership: OrganisationMembership,
  ) => {
    this.analyticsService.track(eventName, {
      membership_id: membership.id,
      user_email: membership.user.email,
      organisation_id: membership.organisation,
      role_id: membership.role.id,
    });
  };
}

export const MEMBERSHIP_PAGINATOR = new InjectionToken('MEMBERSHIP_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    const query = inject(OrganisationMembershipQuery);
    return new PaginatorPlugin(query, { range: true });
  },
});
