<mydendra-second-nav>
  <a
    [routerLink]="['/settings/user']"
    routerLinkActive
    #urla="routerLinkActive"
  >
    <mydendra-second-nav-item
      [active]="urla.isActive"
      icon="user"
      text="Users"
    ></mydendra-second-nav-item>
  </a>
  <a
    [routerLink]="['/settings/role']"
    routerLinkActive
    #urlb="routerLinkActive"
  >
    <mydendra-second-nav-item
      [active]="urlb.isActive"
      icon="work-order"
      text="Roles"
    ></mydendra-second-nav-item>
  </a>
</mydendra-second-nav>
<div class="mx-xl mb-xl settings__content">
  <router-outlet></router-outlet>
</div>
