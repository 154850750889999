@if (role$ | async; as role) {
  <div>
    <div class="row">
      <div class="col-6">
        <mydendra-container>
          <div class="d-flex align-items-center">
            <mydendra-role-status
              [role]="role"
              theme="light"
            ></mydendra-role-status>
            <div class="d-flex flex-column ml-xl">
              <h1 class="subtitle mb-0 mr-xs">
                {{ role.name }}
                <span class="subtle-text">
                  ({{ role.memberships.length }})</span
                >
              </h1>
              <h2 class="my-0">Role</h2>
            </div>
          </div>
        </mydendra-container>
      </div>
    </div>
    <mydendra-role-form
      [editing]="true"
      [model]="model$ | async"
      (edited)="onEdited($event)"
      (back)="onBack($event)"
    ></mydendra-role-form>
  </div>
} @else {
  <mydendra-loading></mydendra-loading>
}
