import { Component, Input } from '@angular/core';
import { OrganisationMembership } from '@services/states/organisation-membership';
import { SharedComponentsModule } from '@shared/components';

@Component({
  selector: 'mydendra-membership-status',
  templateUrl: './membership-status.component.html',
  imports: [SharedComponentsModule],
})
export class MembershipStatusComponent {
  @Input() membership: Pick<OrganisationMembership, 'status'>;
  @Input() theme: 'solid' | 'light' = 'solid';
}
