<div class="modal-header d-flex justify-content-between align-items-center">
  <h1 class="heading-4 mb-0">Confirmation</h1>
  <mydendra-icon
    name="cross"
    class="btn-close"
    (click)="cancel()"
    role="button"
  ></mydendra-icon>
</div>
<div class="modal-body d-flex flex-column align-items-center">
  <p class="mb-xs">Are you sure you want to deactivate this user?</p>
  <p class="subtitle-sm mt-l mb-l">{{ membership.user.email }}</p>
</div>
<div class="modal-footer d-flex justify-content-between">
  <mydendra-button
    type="link"
    icon="cancel"
    icon_position="left"
    (click)="cancel()"
    text="Cancel"
  ></mydendra-button>
  <mydendra-button
    type="warning"
    icon="warning"
    icon_position="left"
    (click)="confirm()"
    text="Deactivate user"
  ></mydendra-button>
</div>
