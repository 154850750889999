import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import {
  OrganisationMembershipService,
  OrganisationMembership,
  OrganisationMembershipState,
  MEMBERSHIP_PAGINATOR,
} from '@services/states/organisation-membership';
import { SharedComponentsModule } from '@shared/components';
import {
  debounceTime,
  startWith,
  switchMap,
  tap,
  combineLatest,
  Observable,
} from 'rxjs';
import { MembershipStatusComponent } from '../membership-status/membership-status.component';

@Component({
  selector: 'mydendra-membership-list',
  templateUrl: './membership-list.component.html',
  styleUrls: ['./membership-list.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    MembershipStatusComponent,
  ],
})
export class MembershipListComponent implements OnDestroy, OnInit {
  paginatorRef =
    inject<PaginatorPlugin<OrganisationMembershipState>>(MEMBERSHIP_PAGINATOR);
  private organisationMembershipService = inject(OrganisationMembershipService);
  private title = inject(Title);

  pagination$: Observable<PaginationResponse<OrganisationMembership>>;
  searchControl = new FormControl();

  private analyticsService = inject(AnalyticsEntity);

  @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef;

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle(`Users | Dendra`);

    this.analyticsService.track('Membership list - view');

    this.paginatorRef.clearCache();
    this.paginatorRef.setFirstPage();

    const searchValue = this.searchControl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
    );

    this.pagination$ = combineLatest([
      this.paginatorRef.pageChanges,
      searchValue.pipe(
        tap(search => {
          if (search) {
            this.analyticsService.track('Membership list - search', { search });
          }
          this.paginatorRef.clearCache();
          this.paginatorRef.setFirstPage();
        }),
      ),
    ]).pipe(
      switchMap(([page, search]) => {
        const page_size = 10;
        const request = () =>
          this.organisationMembershipService.getPage(page, page_size, search);
        return this.paginatorRef.getPage(request);
      }),
    );
  }

  setFocus() {
    this.searchInput.nativeElement.focus();
  }

  ngOnDestroy() {
    this.paginatorRef.clearCache();
    this.paginatorRef.destroy();
  }
}
