import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import { Role, RoleService } from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { RoleFormComponent } from '../role-form/role-form.component';
import { RoleStatusComponent } from '../role-status/role-status.component';

@Component({
  selector: 'mydendra-role-detail',
  templateUrl: './role-detail.component.html',
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    RoleStatusComponent,
    RoleFormComponent,
  ],
})
export class RoleDetailComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private title = inject(Title);
  private toastr = inject(ToastrService);
  private roleService = inject(RoleService);

  @Output() edited = new EventEmitter();

  private analyticsService = inject(AnalyticsEntity);

  // we pass this to formly, which requires mutable objects
  role$ = this.roleService.query
    .selectActive()
    .pipe(map(obj => structuredClone(obj)));

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle(`Detail | Role | Dendra`);

    this.route.params.subscribe(params => {
      this.loadRole(+params.role_id);
    });
  }

  reactivate(model) {
    this.roleService.roleAnalyticsEvent('Reactivate role', model);
    this.roleService.update(model.id, { active: true }).subscribe({
      next: role => this.edited.emit(role),
    });
  }

  loadRole(id) {
    this.roleService.get<Role>(id).subscribe({
      next: role => {
        if (!role) {
          this.roleService.setActive(null);
          this.router.navigate(['/not-exists']);
        }
        this.roleService.roleAnalyticsEvent('View Role', role);
        this.roleService.setActive(role.id);
      },
      error: err => this.onError(err),
    });
  }

  onError(err) {
    this.analyticsService.track('Role action failed', { error: err.error[0] });
    this.toastr.error(err.error[0]);
  }
}
