import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsEntity } from '@dendra/entity-analytics';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { ErrorHandlingService } from '@services/error-handling.service';
import { FlagService } from '@services/states/flags';
import {
  OrganisationMembership,
  OrganisationMembershipService,
} from '@services/states/organisation-membership';
import { Role, RoleService } from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mydendra-membership-deactivation-modal',
  templateUrl: './membership-deactivate.component.html',
  imports: [SharedComponentsModule],
})
export class MembershipDeactivateComponent {
  private bsModalRef = inject(BsModalRef);

  @Input() membership: OrganisationMembership;
  public confirmed = new Subject<boolean>();

  close() {
    this.bsModalRef.hide();
  }

  confirm() {
    this.confirmed.next(true);
    this.close();
  }

  cancel() {
    this.confirmed.next(false);
    this.close();
  }
}

@Component({
  selector: 'mydendra-membership-form',
  templateUrl: './membership-form.component.html',
  styleUrls: ['./membership-form.component.scss'],
  imports: [ReactiveFormsModule, FormlyModule],
})
export class MembershipFormComponent implements OnInit {
  private modalService = inject(BsModalService);
  private roleService = inject(RoleService);
  private toastr = inject(ToastrService);
  private membershipService = inject(OrganisationMembershipService);
  flagService = inject(FlagService);
  private errorHandling = inject(ErrorHandlingService);

  @Input() model: OrganisationMembership;
  @Input() membership$: Observable<OrganisationMembership>;
  @Input() disabled = false;
  @Input() read_only = false;
  @Input() editing = false;
  @Output() created = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() edited = new EventEmitter();

  private analyticsService = inject(AnalyticsEntity);

  form = new FormGroup<{
    role?: FormControl<{ id: number }>;
    tfa_required?: FormControl<boolean>;
  }>({});
  modalRef: BsModalRef;

  options: FormlyFormOptions = {
    formState: {
      read_only: false,
      disabled: false,
      editing: false,
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          wrappers: ['mydendra-container'],
          className: 'col-6',
          templateOptions: {
            title: 'Profile',
            additionalClasses: 'mb-l',
          },
          fieldGroup: [
            {
              type: 'input',
              key: 'user.email',
              templateOptions: {
                label: 'Email',
                required: true,
                type: 'email',
                attributes: {},
              },
              expressionProperties: {
                'templateOptions.disabled':
                  'formState.disabled || formState.editing',
                'templateOptions.attributes.class':
                  "formState.read_only ? 'form-control form-control--read-only' : 'form-control'",
              },
              wrappers: ['mydendra-form-field'],
            },
            {
              className: 'mt-l',
              type: 'toggle',
              key: 'tfa_required',
              templateOptions: {
                label: 'Two factor authentication required',
                required: false,
                handleToggle: $event => {
                  this.form.controls.tfa_required.setValue(
                    $event.target.checked,
                  );
                },
                attributes: {},
              },
              hooks: {
                onInit: field => {
                  // This flag cannot be unset, once set
                  // Setting this onInit to avoid prevent unsetting before save
                  if (
                    !this.options.formState.read_only &&
                    (this.options.formState.disabled ||
                      this.model?.tfa_required)
                  ) {
                    field.templateOptions.disabled = true;
                  }
                },
              },
              expressionProperties: {
                'templateOptions.read_only': 'formState.read_only',
                'templateOptions.defaultValue': 'model.tfa_required',
              },
            },
          ],
        },
        {
          wrappers: ['mydendra-container'],
          className: 'col-6',
          templateOptions: {
            title: 'Role',
            additionalClasses: 'mb-l',
          },
          fieldGroup: [
            {
              wrappers: ['mydendra-form-field'],
              type: 'dropdown',
              key: 'role.id',
              templateOptions: {
                label: 'User role',
                required: true,
                dropdownValueChanged: ($selected: Role) => {
                  this.form.controls.role.setValue({ id: $selected.id });
                },
                options: this.filteredRoles(),
                attributes: {},
              },
              expressionProperties: {
                'templateOptions.disabled': 'formState.disabled',
                'templateOptions.readOnly': 'formState.read_only',
                'templateOptions.initialValue': 'model.role',
              },
            },
            {
              hideExpression: '!model?.role?.flags?.all_locations',
              type: 'role-permission-all-location-access-message',
            },
            {
              type: 'role-locations-list',
              hideExpression:
                'formState.editing | !model?.id | model?.role?.flags?.all_locations',
              templateOptions: {
                label: 'Location Access',
              },
              expressionProperties: {
                'templateOptions.role': 'model.role',
              },
              wrappers: ['mydendra-form-field'],
            },
          ],
        },
      ],
    },

    {
      wrappers: ['mydendra-container'],
      hideExpression: '!model.active || !formState.disabled',
      fieldGroupClassName: 'd-flex align-items-center',
      fieldGroup: [
        {
          type: 'button',
          key: 'deactivate',
          className: 'text-nowrap',
          templateOptions: {
            text: 'Deactivate user',
            type: 'warning',
            icon: 'window-close',
            onClick: $event => {
              $event.preventDefault();
              this.deactivate(this.model);
            },
          },
        },
        {
          template: `<span class="body">This user will lose all access to your organisation's data in myDendra</span>`,
          className: 'ml-xl settings-form-deactivate-container-text',
        },
      ],
    },

    {
      fieldGroupClassName: 'd-flex justify-content-between align-items-center',
      fieldGroup: [
        {
          type: 'button',
          key: 'cancel',
          hideExpression: 'formState.disabled',
          templateOptions: {
            text: 'Cancel',
            type: 'link',
            icon: 'cancel',
            onClick: $event => {
              $event.preventDefault();
              if (!this.model?.id) {
                this.handle_back($event);
              } else {
                this.edit_cancel(this.model);
              }
            },
          },
        },
        {
          fieldGroupClassName: 'd-flex flex-row align-items-center',
          fieldGroup: [
            {
              className: 'ml-l',
              type: 'button',
              key: 'submit',
              hideExpression: 'formState.disabled',
              expressionProperties: {
                'templateOptions.text': 'model.id ? "Save" : "Create user"',
                'templateOptions.disabled': () => {
                  return !this.form.valid;
                },
              },
              templateOptions: {
                button_type: 'submit',
                type: 'primary',
                icon: 'save',
              },
            },
          ],
        },
      ],
    },
  ];

  ngOnInit(): void {
    this.options.formState.read_only = this.read_only;
    this.options.formState.disabled = this.disabled || this.read_only;
    this.options.formState.editing = this.editing;

    this.roleService.getAll().subscribe();
  }

  handle_back(event) {
    this.back.emit(event);
  }

  save(model) {
    if (!this.form.valid) {
      return false;
    }

    if (this.model?.id) {
      this.edit_save(model);
    } else {
      this.create_save(this.form.value);
    }
  }

  deactivate(model) {
    this.membershipService.organisationMembershipAnalyticsEvent(
      'Deactivated membership',
      model,
    );

    this.modalRef = this.modalService.show(MembershipDeactivateComponent, {
      backdrop: 'static',
      class: 'modal modal-dialog-centered',
      initialState: { membership: model },
    });

    this.modalRef.content.confirmed.subscribe((is_confirmed: boolean) => {
      if (is_confirmed) {
        this.membershipService.organisationMembershipAnalyticsEvent(
          'Deactivate membership confirmed',
          model,
        );
        // Set the user as inactive
        this.membershipService.toggleActive(model).subscribe({
          next: membership => {
            this.toastr.success(`Deactivated ${membership.user.email}`);
            this.edited.emit(membership);
          },
          error: this.errorHandling.reportErrorAsToast,
        });
      } else {
        this.membershipService.organisationMembershipAnalyticsEvent(
          'Deactivate membership cancelled',
          model,
        );
      }
    });
  }

  create_save(model) {
    this.membershipService
      .add<OrganisationMembership>(this.membershipService.entityToData(model))
      .subscribe({
        next: membership => {
          this.membershipService.organisationMembershipAnalyticsEvent(
            'Created membership',
            membership,
          );
          this.created.emit(membership);
        },
        error: err => {
          this.options.formState.disabled = false;
          this.onError(err);
        },
      });
  }

  edit_cancel(model) {
    this.options.resetModel();
    this.membershipService.organisationMembershipAnalyticsEvent(
      'Editing membership cancelled',
      model,
    );
    this.back.emit(event);
  }

  edit_save(model) {
    this.options.formState.disabled = true;

    this.membershipService
      .update<OrganisationMembership>(
        model.id,
        this.membershipService.entityToData(model),
      )
      .subscribe({
        next: membership => {
          this.membershipService.organisationMembershipAnalyticsEvent(
            'Editing membership saved',
            membership,
          );
          this.edited.emit(membership);
          this.options.formState.disabled = true;
        },
        error: err => {
          this.options.formState.disabled = false;
          this.onError(err);
        },
      });
  }

  onError(err: HttpErrorResponse) {
    this.errorHandling.reportErrorAsToast(err);
    this.analyticsService.track('Membership form action failed', {
      error: err.error,
    });
  }

  filteredRoles() {
    return combineLatest([
      this.roleService.query.active$,
      this.membershipService.query.selectActive(),
    ]).pipe(
      map(([roles, membership]) => {
        if (membership) {
          return [
            membership.role,
            ...roles.filter(role => role.id !== membership.role.id),
          ];
        }
        return roles;
      }),
    );
  }
}
