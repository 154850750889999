import { Component, Input } from '@angular/core';
import { Role } from '@services/states/role';
import { SharedComponentsModule } from '@shared/components';

@Component({
  selector: 'mydendra-role-status',
  templateUrl: './role-status.component.html',
  imports: [SharedComponentsModule],
})
export class RoleStatusComponent {
  @Input() role: Role;
  @Input() theme: 'solid' | 'light' = 'solid';
}
